// assets
import {
    IconReport
} from '@tabler/icons';

// constant
const icons = {
    IconReport: IconReport
};

//-----------------------|| UTILITIES MENU ITEMS ||-----------------------//

export const report = {
    id: 'report',
    title: 'Laporan',
    type: 'group',
    children: [
        {
            id: 'report-report',
            title: 'Laporan',
            type: 'item',
            url: '/report/export',
            icon: icons['IconReport'],
            breadcrumbs: false
        }
    ]
};
