import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import soCartReducer from './soCartReducer';
import poCartReducer from './poCartReducer';
import returnCartReducer from './returnCartReducer';
import dialogReducer from './dialogReducer';

//-----------------------|| COMBINE REDUCER ||-----------------------//

const reducer = combineReducers({
    customization: customizationReducer,
    so_cart: soCartReducer,
    po_cart: poCartReducer,
    sr_cart: returnCartReducer,
    dialog: dialogReducer
});

export default reducer;
