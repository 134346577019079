import * as actionTypes from './actions';

const initState = {
    selectCustomerDialog: false,
    selectGudangDialog: false,
    selectSupplierDialog: false,
    selectSalesItemDialog: false,
    piutangHistoryDialog: {
        open: false,
        items: [],
        total_amount: 0,
        paid_amount: 0,
        remaining_debt: 0
    }
}

const dialogReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.DIALOG_SELECT_CUSTOMER:
            return {
                ...state,
                selectCustomerDialog: action.isOpen
            };
        case actionTypes.DIALOG_SELECT_GUDANG:
            return {
                ...state,
                selectGudangDialog: action.isOpen
            };
        case actionTypes.DIALOG_SELECT_SUPPLIER:
            return {
                ...state,
                selectSupplierDialog: action.isOpen
            };
        case actionTypes.DIALOG_SELECT_SALES_ITEM:
            return {
                ...state,
                selectSalesItemDialog: action.isOpen
            };
        case actionTypes.DIALOG_PIUTANG_HISTORY:
            return {
                ...state,
                piutangHistoryDialog: {
                    open: action.isOpen,
                    items: action.items,
                    total_amount: action.total_amount,
                    paid_amount: action.paid_amount,
                    remaining_debt: action.remaining_debt
                }
            };
        default:
            return state;
    }
}

export default dialogReducer;