// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const CUST_RESET = '@customization/RESET';

// action - sales order cart
export const SO_CART_ADD_ITEM = '@socart/ADD_ITEM';
export const SO_CART_DELETE_ITEM = '@socart/DELETE_ITEM';
export const SO_CART_UPDATE_ITEM = '@socart/UPDATE_ITEM';
export const SO_CART_UPDATE_AMOUNT = '@socart/UPDATE_AMOUNT';
export const SO_CART_UPDATE_INFO = '@socart/UPDATE_INFO';
export const SO_CART_DESTROY = '@socart/DESTROY';
export const SO_CART_SELECT_CUSTOMER = '@socart/SELECT_CUSTOMER';
export const SO_CART_SELECT_GUDANG = '@socart/SELECT_GUDANG';
export const SO_CART_SELECT_SALES = '@socart/SELECT_SALES';
export const SO_CART_SELECT_RESELLER = '@socart/SELECT_RESELLER';

// action - purchase order cart
export const PO_CART_UPDATE_INFO = '@pocart/UPDATE_INFO';
export const PO_CART_UPDATE_ITEM_INFO = '@pocart/UPDATE_ITEM_INFO';
export const PO_CART_ADD_ITEM = '@pocart/ADD_ITEM';
export const PO_CART_REMOVE_ITEM = '@pocart/REMOVE_ITEM';
export const PO_CART_REMOVE_ITEM_BY_CODE = '@pocart/REMOVE_ITEM_BY_CODE';
export const PO_CART_DESTROY = '@pocart/DESTROY';
export const PO_CART_UPDATE_RETUR_ITEM = '@pocart/UPDATE_RETUR_ITEM';
export const PO_CART_CLEAR_ITEM = '@pocart/CLEAR_ITEM';

// action - purchase order cart
export const SR_CART_UPDATE_INFO = '@srcart/UPDATE_INFO';
export const SR_CART_ADD_ITEM = '@srcart/ADD_ITEM';
export const SR_CART_UPD_ITEM_QTY = '@srcart/UPD_ITEM_QTY';
export const SR_CART_REMOVE_ITEM = '@srcart/REMOVE_ITEM';
export const SR_CART_REMOVE_ITEM_BY_CODE = '@srcart/REMOVE_ITEM_BY_CODE';
export const SR_CART_DESTROY = '@srcart/DESTROY';

// action - dialog actions
export const DIALOG_SELECT_CUSTOMER = '@open/SELECT_CUSTOMER_DIALOG';
export const DIALOG_SELECT_GUDANG = '@open/SELECT_GUDANG_DIALOG';
export const DIALOG_SELECT_SUPPLIER = '@open/SELECT_GUDANG_SUPPLIER';
export const DIALOG_SELECT_SALES_ITEM = '@open/SELECT_SALES_ITEM';
export const DIALOG_PIUTANG_HISTORY = '@open/PIUTANG_HISTORY';
