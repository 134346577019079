import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { Typography } from '@material-ui/core';

// project imports
import NavGroup from './NavGroup';
import menuItem from './../../../../menu-items'; // menu defined in clients
import { Redirect } from 'react-router';
import { MENU_OPEN } from '../../../../store/actions';

//-----------------------|| SIDEBAR MENU LIST ||-----------------------//

const MenuList = () => {
    const menuItems = JSON.parse(localStorage.getItem('menu_items'));
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);

    if (!menuItems) {
        localStorage.clear();
        return <Redirect to="/"/>
    }

    const navItems = menuItems.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return navItems;
};

export default MenuList;
