import { dashboard } from './dashboard';
import { transaction } from './transaction';
import { settings } from './settings';
import { report } from './report';
import { masterdata } from './masterdata'

//-----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
    items: [
        dashboard, 
        transaction, 
        report, 
        masterdata, 
        settings
    ]
};

export default menuItems;
