import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { useCookies } from "react-cookie";

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

// project imports
import config from './../config';

//-----------------------|| ROUTING RENDER ||-----------------------//

const Routes = () => {
    let defaultPath = config.defaultPath;
    const levelCode = localStorage.getItem('level_code');

    if (levelCode == 'sales') defaultPath = '/order/sales'
    if (levelCode == 'admin_order') defaultPath = '/order/purchase'
    if (levelCode == 'pic_gudang') defaultPath = '/excess-purchase';

    return (
        <Switch>
            <Redirect exact from="/" to={defaultPath} />
            <React.Fragment>
                {/* Routes for authentication pages */}
                <AuthenticationRoutes />

                {/* Routes for main layouts */}
                <MainRoutes />
            </React.Fragment>
        </Switch>
    );
};

export default Routes;
