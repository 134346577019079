import * as actionTypes from './actions';

const initState = {
    cartItems: [],
    orderNo: `SR-${new Date().getTime()}`,
    orderDate: new Date(),
    salesOrder: {
        id: null,
        orderNo: ''
    },
    salesName: '',
    gudang: '',
    reason: ''
}

const returnCartReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.SR_CART_UPDATE_INFO:
            return updateCartInfo(state, action);
        case actionTypes.SR_CART_ADD_ITEM:
            return addItem(state, action);
        case actionTypes.SR_CART_UPD_ITEM_QTY:
            return updateItemQty(state, action);
        case actionTypes.SR_CART_REMOVE_ITEM:
            return removeItem(state, action);
        case actionTypes.SR_CART_REMOVE_ITEM_BY_CODE:
            return removeItemByCode(state, action);
        case actionTypes.SR_CART_DESTROY:
            return destroy();
        default:
            return state;
    }
}

function destroy() {
    return {
        cartItems: [],
        orderNo: `SR-${new Date().getTime()}`,
        orderDate: new Date(),
        salesOrder: {
            id: null,
            orderNo: ''
        },
        salesName: '',
        gudang: '',
        reason: ''
    };
}

function addItem(state, action) {
    action.product.qty_return = action.product.qty;
    state.cartItems = [...state.cartItems, action.product];
    state.salesOrder = {
        id: action.salesOrder.id,
        orderNo: action.salesOrder.orderNo
    };
    state.salesName = action.salesName;
    state.gudang = action.gudang;
    return state;
}

function updateItemQty(state, action) {
    for (var i=0; i<state.cartItems.length; i++) {
        if (state.cartItems[i].product_code == action.productCode) {
            var val = action.qtyReturn;
            if (val <= 0 || val > state.cartItems[i].qty) val = state.cartItems[i].qty;
            state.cartItems[i].qty_return = val;
        }
    }
    return state;
}

function removeItem(state, action) {
    var newItems = [];
    state.cartItems.forEach(element => {
        if (element.id != action.id) {
            newItems.push(element);
        }
    });
    if (newItems.length <= 0) {
        state.salesOrder= {
            id: null,
            orderNo: ''
        };
        state.salesName = '';
        state.gudang = '';
    }
    state.cartItems = newItems;
    return state;
}

function removeItemByCode(state, action) {
    var newItems = [];
    state.cartItems.forEach(element => {
        if (element.product_code != action.productCode) {
            newItems.push(element);
        }
    });
    if (newItems.length <= 0) {
        state.salesOrder= {
            id: null,
            orderNo: ''
        };
        state.salesName = '';
        state.gudang = '';
    }
    state.cartItems = newItems;
    return state;
}

function updateCartInfo(state, action) {
    if (action.field == 'order_date') {
        return {
            ...state,
            orderDate: action.value
        }
    }
    if (action.field == 'reason') {
        return {
            ...state,
            reason: action.value
        }
    }
    return state;
}

export default returnCartReducer;