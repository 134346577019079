import { fDateOrder } from '../utils/formatTime';
import * as actionTypes from './actions';

const initState = {
    cartItems: [],
    usedReturItems: [],
    purchasePriceItems: [],
    supplier: {
        id: null,
        name: ''
    },
    gudang: {
        id: null,
        name: ''
    },
    orderNo: `PO-${fDateOrder(new Date())}`,
    orderDate: new Date(),
    invoiceNo: '',
    invoiceDate: new Date(),
    paymentMethod: 'lunas'
}

const poCartReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.PO_CART_UPDATE_INFO:
            return updateCartInfo(state, action);
        case actionTypes.PO_CART_UPDATE_ITEM_INFO:
            return updateCartItemInfo(state, action);
        case actionTypes.PO_CART_ADD_ITEM:
            return addItem(state, action);
        case actionTypes.PO_CART_REMOVE_ITEM:
            return removeItem(state, action);
        case actionTypes.PO_CART_UPDATE_RETUR_ITEM:
            return updateReturItem(state, action);
        case actionTypes.PO_CART_REMOVE_ITEM_BY_CODE:
            return removeItemByCode(state, action);
        case actionTypes.PO_CART_CLEAR_ITEM:
            return clearItems(state);
        case actionTypes.PO_CART_DESTROY:
            return destroy();
        default:
            return state;
    }
}

function updateCartItemInfo(state, action) {
    if (action.field == 'purchase_price') return updatePurchasePrice(state, action.productId, action.value);
    if (action.field == 'qty') return updateQtyItem(state, action);
    if (action.field == 'qty_stock') return updateQtyFromStock(state, action);
    return state;
}

function updatePurchasePrice(state, productId, amount) {
    let newAmount = Number(amount);
    if (isNaN(newAmount)) return state;

    for (var i=0; i<state.cartItems.length; i++) {
        if (state.cartItems[i].product_id == productId) {
            state.cartItems[i].purchase_price = newAmount;
        }
    }

    return state;
}

function updateQtyItem(state, action) {
    let newQty = action.edit_qty;
    if (isNaN(newQty)) return state;

    for (var i=0; i<state.cartItems.length; i++) {
        if (state.cartItems[i].id == action.id) {
            state.cartItems[i].edit_qty = newQty;
        }
    }

    return state;
}

function updateQtyFromStock(state, action) {
    let qtyFromStock = action.qty_from_stock;
    let qtyFromExcess = action.qty_from_excess;
    let qtyFromGudang = action.qty_from_gudang;

    if (isNaN(qtyFromStock) || isNaN(qtyFromExcess) || isNaN(qtyFromGudang)) return state;

    for (var i=0; i<state.cartItems.length; i++) {
        if (state.cartItems[i].id == action.id) {
            state.cartItems[i].qty_from_stock = qtyFromStock;
            state.cartItems[i].qty_from_excess = qtyFromExcess;
            state.cartItems[i].qty_from_gudang = qtyFromGudang;
        }
    }

    return state;
}

function destroy() {
    var curDate = new Date();
    return {
        cartItems: [],
        usedReturItems: [],
        purchasePriceItems: [],
        supplier: {
            id: null,
            name: ''
        },
        gudang: {
            id: null,
            name: ''
        },
        orderNo: `PO-${fDateOrder(curDate)}`,
        orderDate: curDate,
        invoiceNo: '',
        invoiceDate: curDate,
        paymentMethod: 'lunas'
    };
}

function clearItems(state) {
    return {
        ...state,
        cartItems: []
    }
}

function updateReturItem(state, action) {
    for (var i=0; i<state.cartItems.length; i++) {
        if (state.cartItems[i].product_id == action.product_id) {
            var usedQty = action.used_qty;
            if (usedQty > state.cartItems[i].av_qty_return) {
                usedQty = state.cartItems[i].av_qty_return;
            }
            if (usedQty > state.cartItems[i].qty) {
                usedQty = state.cartItems[i].qty
            }
            state.cartItems[i].used_return_qty = usedQty;
            break;
        }
    }
    return state;
}

function addItem(state, action) {
    var product = {
        ...action.product,
        edit_qty: action.product.qty,
        total_qty: action.product.qty,
        row_span: 1,
        qty_from_stock: 0,
        qty_from_excess: 0,
        qty_from_gudang: 0
    }
    state.cartItems = [...state.cartItems, product];
    return state;
}

function removeItem(state, action) {
    var newItems = [];
    state.cartItems.forEach(element => {
        if (element.id != action.id) {
            newItems.push(element);
        }
    });
    state.cartItems = newItems;
    return state;
}

function removeItemByCode(state, action) {
    var newItems = [];
    state.cartItems.forEach(element => {
        if (element.product_code == action.productCode && element.variant_code == action.variantCode) {
            // continue
        } else {
            newItems.push(element);
        }
    });
    state.cartItems = newItems;
    return state;
}

function updateCartInfo(state, action) {
    if (action.field == 'supplier') {
        return {
            ...state,
            supplier: {
                id: action.value.id,
                name: action.value.name
            }
        }
    }
    if (action.field == 'gudang') {
        var prevGudang = state.gudang.id;
        if (prevGudang && prevGudang != action.value.id) {
            state.cartItems = [];
        }
        state.gudang = {
            id: action.value.id,
            name: action.value.name
        }
        return state;
    }
    if (action.field == 'order_date') {
        return {
            ...state,
            orderDate: action.value
        }
    }
    if (action.field == 'invoice_no') {
        return {
            ...state,
            invoiceNo: action.value
        }
    }
    if (action.field == 'invoice_date') {
        return {
            ...state,
            invoiceDate: action.value
        }
    }
    return state;
}

export default poCartReducer;