// assets
import { 
    IconPackage,
    IconUsers,
    IconBuildingFactory,
    IconBuildingWarehouse,
    IconSitemap
} from '@tabler/icons';

// constant
const icons = {
    IconPackage: IconPackage,
    IconUsers: IconUsers,
    IconBuildingFactory: IconBuildingFactory,
    IconBuildingWarehouse: IconBuildingWarehouse,
    IconSitemap: IconSitemap
};

//-----------------------|| UTILITIES MENU ITEMS ||-----------------------//

export const masterdata = {
    id: 'masterdata',
    title: 'Master Data',
    type: 'group',
    children: [
        {
            id: 'md-product',
            title: 'Produk',
            type: 'item',
            url: '/master-data/product',
            icon: icons['IconPackage'],
            breadcrumbs: false
        },
        {
            id: 'md-customer',
            title: 'Pelanggan',
            type: 'item',
            url: '/master-data/customer',
            icon: icons['IconUsers'],
            breadcrumbs: false
        },
        {
            id: 'md-supplier',
            title: 'Supplier',
            type: 'item',
            url: '/master-data/supplier',
            icon: icons['IconBuildingFactory'],
            breadcrumbs: false
        },
        {
            id: 'md-gudang',
            title: 'Gudang',
            type: 'item',
            url: '/master-data/gudang',
            icon: icons['IconBuildingWarehouse'],
            breadcrumbs: false
        },
        {
            id: 'md-province',
            title: 'Provinsi',
            type: 'item',
            url: '/master-data/province',
            icon: icons['IconSitemap'],
            breadcrumbs: false
        },
        {
            id: 'md-city',
            title: 'Kota',
            type: 'item',
            url: '/master-data/city',
            icon: icons['IconSitemap'],
            breadcrumbs: false
        },
    ]
};
