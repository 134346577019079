import React from 'react';

// material-ui
import { Box } from '@material-ui/core';
import LogoIcon from './../assets/images/logo.png';

const Logo = () => {
    return (
        <Box component="img" src={LogoIcon} sx={{ height: 50 }} />
    );
};

export default Logo;
