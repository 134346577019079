import * as actionTypes from './actions';

const initState = {
    cartItems: [],
    customer: {
        id: null,
        name: ''
    },
    gudang: {
        id: null,
        name: ''
    },
    sales: {
        id: null,
        name: ''
    },
    reseller: {
        id: null,
        name: ''
    },
    orderDate: new Date(),
    paymentMethod: 'lunas',
    description: 'COD',
    totalAmount: 0,
    discountPercent: 0,
    discount: 0,
    deliveryFee: 0,
    finalAmount: 0,
    paymentAmount: 0,
    change: 0,
}

const soCartReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.SO_CART_ADD_ITEM:
            return addItem(state, action.product, action.variant);
        case actionTypes.SO_CART_DELETE_ITEM:
            return deleteItem(state, action.productId, action.variantCode);
        case actionTypes.SO_CART_UPDATE_ITEM:
            return updateItem(state, action);
        case actionTypes.SO_CART_UPDATE_AMOUNT:
            return updateAmount(state, action.field, action.value);
        case actionTypes.SO_CART_UPDATE_INFO:
            return updateCartInfo(state, action);
        case actionTypes.SO_CART_DESTROY:
            return destroyCart();
        case actionTypes.SO_CART_SELECT_CUSTOMER:
            return {
                ...state,
                customer: {
                    id: action.id,
                    name: action.name
                }
            };
        case actionTypes.SO_CART_SELECT_GUDANG:
            return updateGudang(state, action);
        case actionTypes.SO_CART_SELECT_SALES:
            return updateSales(state, action);
        case actionTypes.SO_CART_SELECT_RESELLER:
            return updateReseller(state, action);
        default:
            return state;
    }
}

function updateGudang(state, action) {
    var cartItems = state.cartItems;
    if (action.id != state.gudang.id) {
        for (var i=0; i<cartItems.length; i++) {
            cartItems[i].qty_from_return = 0;
        }
    }
    return {
        ...state,
        cartItems: cartItems,
        gudang: {
            id: action.id,
            name: action.name
        }
    };
}

function updateSales(state, action) {
    var cartItems = state.cartItems;
    return {
        ...state,
        cartItems: cartItems,
        sales: {
            id: action.id,
            name: action.name
        }
    };
}

function updateReseller(state, action) {
    var cartItems = state.cartItems;
    return {
        ...state,
        cartItems: cartItems,
        reseller: {
            id: action.id,
            name: action.name
        }
    };
}

function destroyCart() {
    return {
        cartItems: [],
        customer: {
            id: null,
            name: ''
        },
        gudang: {
            id: null,
            name: ''
        },
        sales: {
            id: null,
            name: ''
        },
        reseller: {
            id: null,
            name: ''
        },
        orderDate: new Date(),
        paymentMethod: 'lunas',
        description: 'COD',
        totalAmount: 0,
        discountPercent: 0,
        discount: 0,
        deliveryFee: 0,
        finalAmount: 0,
        paymentAmount: 0,
        change: 0,
    };
}

function updateCartInfo(state, action) {
    if (action.field == 'description') return {...state, description: action.value}
    if (action.field == 'order_date') return {...state, orderDate: action.value}
    if (action.field == 'payment_method') {
        state.paymentAmount = null;
        state.paymentMethod = action.value;
        return adjustAmountValues(state);
    }
    return state;
}

function addItem(state, product, variant) {
    let existedItem;
    for (var i=0; i<state.cartItems.length; i++) {
        var item = state.cartItems[i];
        if (item.id == product.id && item.variant_code == variant.code) {
            existedItem = item;
        }
    }
    product.selling_price = Number(product.selling_price);
    let newTotal = state.totalAmount + Number(product.selling_price);
    if(existedItem) {
        existedItem.qty += 1;
        existedItem.total += Number(product.selling_price);
        state.totalAmount = newTotal;
        return adjustAmountValues(state);
    } else {
        var soItem = {
            id: product.id,
            name: product.name,
            weight_gr: product.weight_gr,
            qty: 1,
            qty_from_return: 0,
            selling_price: product.selling_price,
            total: Number(product.selling_price),
            variant_code: variant.code,
            variant_name: variant.text
        }
        state.cartItems = [...state.cartItems, soItem];
        state.totalAmount = newTotal;
        return adjustAmountValues(state);
    }
}

function deleteItem(state, productId, variantCode) {
    let newItems = [];
    let deletedAmount = 0;
    state.cartItems.forEach(element => {
        if (element.id === productId && element.variant_code === variantCode) {
            deletedAmount = element.total;
        } else {
            newItems.push(element);
        }
    });
    let total = state.totalAmount - Number(deletedAmount);
    state.cartItems = newItems;
    state.totalAmount = total;
    return adjustAmountValues(state);
}

function updateItem(state, action) {
    if (action.field == 'qty') return updateQty(state, action.productId, action.value, action.variantCode);
    if (action.field == 'selling_price') return updateSellingPrice(state, action.productId, action.value, action.variantCode);
    if (action.field == 'description') return updateItemDescription(state, action.productId, action.value, action.variantCode);
    if (action.field == 'qty_from_return') return updateQtyFromReturn(state, action.productId, action.value, action.variantCode, action.stockReturnUsage);
    return state;
}

function updateQtyFromReturn(state, productId, qtyFromReturn, variantCode, stockReturnUsage) {
    let existedItem = state.cartItems.find(item => item.id === productId && item.variant_code == variantCode)
    if(existedItem) {
        existedItem.qty_from_return = qtyFromReturn;
        existedItem.stock_return_usage = stockReturnUsage;
        return state;
    } else{
        return state;
    }
} 

function updateItemDescription(state, productId, note, variantCode) {
    let existedItem = state.cartItems.find(item => item.id === productId && item.variant_code == variantCode)
    if(existedItem) {
        existedItem.description = note;
        return state;
    } else{
        return state;
    }
}

function updateQty(state, productId, qty, variantCode) {
    let newQty = Number(qty);
    if (isNaN(newQty)) return state;
    if (newQty <= 0) return deleteItem(state, productId, variantCode);

    let existedItem = state.cartItems.find(item => item.id === productId && item.variant_code == variantCode)
    if(existedItem) {
        let newTotal = Number(existedItem.selling_price) * newQty;
        existedItem.total = newTotal;
        existedItem.qty = newQty;
        
        var newTotalAmount = 0;
        state.cartItems.forEach((e) => {
            newTotalAmount += e.total;
        });
        state.totalAmount = newTotalAmount;
        
        return adjustAmountValues(state);
    } else{
        return state;
    }
}

function updateSellingPrice(state, productId, amount, variantCode) {
    let newAmount = Number(amount);
    if (isNaN(newAmount)) return state;
    if (newAmount <= 0) return deleteItem(state, productId, variantCode);

    let existedItem = state.cartItems.find(item => item.id === productId && item.variant_code === variantCode)
    if(existedItem) {
        let newTotal = newAmount * existedItem.qty;
        existedItem.selling_price = newAmount;
        existedItem.total = newTotal;
        
        var newTotalAmount = 0;
        state.cartItems.forEach((e) => {
            newTotalAmount += e.total;
        });
        state.totalAmount = newTotalAmount;

        return adjustAmountValues(state);
    } else{
        return state;
    }
}

function updateAmount(state, field, value) {
    if (field == 'discount') {
        var val = Number(value);
        if (isNaN(val) || value == '') {
            state.discount = 0;
            state.discountPercent = 0;
        } else {
            if (val > state.totalAmount) val = state.totalAmount;
            if (val < 0) val = 0;
            state.discount = val;
            
            var percentVal = (val / state.totalAmount) * 100;
            state.discountPercent = Number(percentVal);
        }
    }
    if (field == 'discount_percent') {
        var val = Number(value);
        if (isNaN(val) || value == '') {
            state.discount = 0;
            state.discountPercent = 0;
        } else {
            if (val > 100) val = 100;
            if (val < 0) val = 0;
            state.discountPercent = val;
            
            var amountVal = state.totalAmount * (val / 100);
            state.discount = Number(amountVal);
        }
    }
    if (field == 'delivery_fee') state.deliveryFee = Number(value);
    if (field == 'payment_amount') state.paymentAmount = Number(value);
    return adjustAmountValues(state);
}

function adjustAmountValues(state) {
    var finalAmount = (state.totalAmount - state.discount) + state.deliveryFee;
    var change = state.paymentAmount - finalAmount;
    var paymentMethod = state.paymentAmount < finalAmount ? 'angsur' : 'lunas';
    return {
        ...state,
        finalAmount: finalAmount,
        change: change,
        paymentMethod: paymentMethod
    }
}

export default soCartReducer;