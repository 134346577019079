import React from 'react';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import { routes } from './routes';

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();
    const isLogin = localStorage.getItem("isLogin");

    const routePathList = routes.map((route) => {
        return route.path;
    });

    const routeElements = routes.map((route) => {
        return <Route key={route.path} exact={route.exact} path={route.path} component={route.component} />
    });

    if (isLogin != "true") return <Redirect to="/login"/>

    return (
        <Route
            path={routePathList}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    {routeElements}
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
