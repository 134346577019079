// assets
import {
    IconShoppingCart,
    IconBasket,
    IconShoppingCartX,
    IconCreditCard,
    IconPackage
} from '@tabler/icons';

// constant
const icons = {
    IconShoppingCart: IconShoppingCart,
    IconBasket: IconBasket,
    IconShoppingCartX: IconShoppingCartX,
    IconCreditCard: IconCreditCard,
    IconPackage: IconPackage
};

//-----------------------|| UTILITIES MENU ITEMS ||-----------------------//

export const transaction = {
    id: 'transaction',
    title: 'Transaksi',
    type: 'group',
    children: [
        {
            id: 'trans-order-sales',
            title: 'Penjualan',
            type: 'item',
            url: '/order/sales',
            icon: icons['IconShoppingCart'],
            breadcrumbs: false
        },
        {
            id: 'trans-order-purchase',
            title: 'Pembelian',
            type: 'item',
            url: '/order/purchase',
            icon: icons['IconBasket'],
            breadcrumbs: false
        },
        {
            id: 'trans-order-sales-piutang',
            title: 'Piutang Penjualan',
            type: 'item',
            url: '/order/sales/piutang',
            icon: icons['IconCreditCard'],
            breadcrumbs: false
        },
        {
            id: 'trans-order-sales-retur',
            title: 'Retur Penjualan',
            type: 'item',
            url: '/order/sales/return',
            icon: icons['IconShoppingCartX'],
            breadcrumbs: false
        },
        {
            id: 'trans-excess-purchase',
            title: 'Barang Lebih',
            type: 'item',
            url: '/excess-purchase',
            icon: icons['IconPackage'],
            breadcrumbs: false
        }
    ]
};
